import { axios } from "@/configuration";
import Learnlink from "@learnlink/interfaces";

const ENDPOINT = "/admin/course-feedback";

export async function selectCourseFeedback(options: {
  search?: string;
  page?: number;
  days?: string[];
  courseID?: string[];
  handled?: "all" | "handled" | "unhandled";
}): Promise<Learnlink.CourseFeedback.default[]> {
  const params = new URLSearchParams();

  if (options.search) {
    params.append("search", options.search);
  }
  if (options.page) {
    params.append("page", options.page.toString());
  }

  if (options.days) {
    params.append("days", options.days.join(","));
  }

  if (options.courseID) {
    params.append("courseID", options.courseID.join(","));
  }

  if (options.handled && options.handled !== "all") {
    params.append("handled", (options.handled === "handled") ? "true" : "false");
  }

  const courseRes = await axios.get<Learnlink.CourseFeedback.default[]>(
    `${ENDPOINT}/select${
      params.toString() ? "?" + params.toString() : ""
    }`,
  );

  return courseRes.data;
}

export async function updateCourseFeedback(data: Omit<Learnlink.CourseFeedback.CourseFeedback, "ID">): Promise<void> {
  await axios.put(`${ENDPOINT}/update`, data);
}
