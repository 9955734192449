import { render, staticRenderFns } from "./HandleFeedbackDialog.vue?vue&type=template&id=36b6e5cb&"
import script from "./HandleFeedbackDialog.vue?vue&type=script&lang=ts&"
export * from "./HandleFeedbackDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports