import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VAlert,{attrs:{"type":"info","width":"600","text":"","shaped":""}},[_vm._v(" Du kan oppdatere nyheter, eller slette dem. De som ikke allerede har sett nyheten vil ikke få sett den originale nyheten om den slettes eller oppdateres. ")]),_c(VCard,{staticClass:"pa-4",attrs:{"height":"400","width":"600"}},[(_vm.loading)?_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}}):_c(VContainer,[_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"label":"Emne","outlined":"","dense":""},model:{value:(_vm.newsMessage.title),callback:function ($$v) {_vm.$set(_vm.newsMessage, "title", $$v)},expression:"newsMessage.title"}})],1)],1),_c(VRow,{staticClass:"mt-0"},[_c(VCol,[_c(VTextarea,{attrs:{"label":"Innhold","outlined":"","shaped":""},model:{value:(_vm.newsMessage.message),callback:function ($$v) {_vm.$set(_vm.newsMessage, "message", $$v)},expression:"newsMessage.message"}})],1)],1),_c(VRow,{staticClass:"mt-0"},[_c(VCol,[_c(VBtn,{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteNewsMessage()}}},[_vm._v(" Slett ")])],1),_c(VCol,[_c(VBtn,{attrs:{"color":"warning","disabled":_vm.formValidation},on:{"click":function($event){return _vm.update()}}},[_vm._v(" Oppdater ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }