




































































import { deleteNewsMessage, updateNewsMessage } from "@/api/news-service";
import Learnlink from "@learnlink/interfaces";
import linkify from "@/helpers/linkifyWithSeparator";
import store from "@/store/store";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "NewsCard",
  props: {
    newsMessage: {
      type: Object as PropType<Learnlink.NewsMessage.default>,
      required: true
    }
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    formValidation(): boolean {
      return !this.newsMessage.title || !this.newsMessage.message;
    },
  },
  methods: {
    async update() {
      this.loading = true;
      this.newsMessage.message = linkify(this.newsMessage.message);

      try {
        await updateNewsMessage(this.newsMessage);

        await store.dispatch("displayAlert", {
          color: "success",
          message: "Oppdatert! 🥳",
        });
      }
      catch (e) {
        await store.dispatch("displayAlert", {
          color: "error",
          message: e,
        });
      }
      this.loading = false;
    },
    async deleteNewsMessage() {
      try {
        await deleteNewsMessage(this.newsMessage.ID);

        await store.dispatch("displayAlert", {
          color: "success",
          message: "Slettet! 🥳",
        });
        this.$emit("deleted");
      }
      catch (e) {
        await store.dispatch("displayAlert", {
          color: "error",
          message: e,
        });
      }
      this.loading = false;
    },
  },
});
