











import store from "@/store/store";
import Learnlink from "@learnlink/interfaces";
import { defineComponent, onMounted, PropType, reactive, ref, watchEffect } from "@vue/composition-api";

export default defineComponent({
  name: "CoursePicker",
  props: {
    value: {
      type: [String, Array] as PropType<string | string[]>,
      required: true,
    },
    multiple: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    const vm = reactive({
      courses: [] as { value: string, text: string }[],
    });

    function getCourseTitle(course: Learnlink.Course.default): string {
      return course.location
        ? `${course.title} – (${getLocation(course)} i ${course.weeks} ${course.weeks === 1 ? "uke" : "uker"})`
        : `${course.title} (${course.weeks} ukers kurs)`;
    }

    function getLocation(course: Learnlink.Course.default): string {
      if (course.location === "physical") return "fysisk";
      return course.location;
    }

    onMounted(async () => {
      vm.courses = store.state.courses.map(course => ({
        value: course.ID,
        text: getCourseTitle(course),
      }));
    });

    return {
      _value,
      vm,
    };
  },
});
