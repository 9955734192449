import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[(_vm.loading)?_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}}):_c(VContainer,[_c(VRow,{staticClass:"mb-4"},[_c(VSpacer),_c(VBtn,{attrs:{"loading":_vm.loading,"outlined":""},on:{"click":function($event){return _vm.mount()}}},[_c(VIcon,{attrs:{"aria-label":"false"}},[_vm._v(" mdi-autorenew ")])],1)],1),_c(VRow,[_c(VExpansionPanels,{attrs:{"focusable":""}},_vm._l((_vm.newsMessages),function(newsMessage){return _c(VExpansionPanel,{key:newsMessage.ID},[_c(VExpansionPanelHeader,[_c('h3',[_vm._v(" "+_vm._s(newsMessage.title)+" ")]),_c('h5',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.readableDate(newsMessage.created))+" ")])]),_c(VExpansionPanelContent,{attrs:{"align":"center"}},[_c('news-card',{attrs:{"news-message":newsMessage},on:{"deleted":function($event){return _vm.mount()}}})],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }