












































































































import { defineComponent, reactive, ref } from "@vue/composition-api";
import { NegativeReasons } from "@/helpers/feedbackUtils";
import { ratingSymbols } from "@/helpers/stringUtils";
import FeedbackCard from "@/components/feedback/FeedbackCard.vue";
import CoursePickerVue from "../courses/CoursePicker.vue";
import useAsyncData from "@/helpers/useAsyncData";
import { selectCourseFeedback } from "@/api/course-feedback-service";
import CourseFeedbackCard from "./CourseFeedbackCard.vue";

export default defineComponent({
  components: { FeedbackCard, CoursePickerVue, CourseFeedbackCard },
  name: "ProFeedback",
  setup() {
    const vm = reactive({
      courses: [] as string[],
      days: [] as string[],
      handledFilter: "all" as "all" | "handled" | "unhandled",
    });
    const search = ref("");
    const offsetPage = ref(0);

    const { isLoading, refresh, data: feedback } = useAsyncData(() => {
      return selectCourseFeedback({
        page: offsetPage.value,
        search: search.value,
        handled: vm.handledFilter,
        courseID: vm.courses,
        days: vm.days,
      });
    });

    const searchFunction = () => refresh();

    const nextPage = () => {
      offsetPage.value += 1;
      refresh();
    };

    const previousPage = () => {
      offsetPage.value -= 1;

      if (offsetPage.value < 0) {
        offsetPage.value = 0;
      }
      else {
        refresh();
      }
    };

    const resetPage = () => {
      offsetPage.value = 0;
      refresh();
    };

    return {
      feedback,
      isLoading,
      NegativeReasons,
      ratingSymbols,
      offsetPage,
      vm,
      searchFunction,
      search,
      nextPage,
      previousPage,
      resetPage,
    };
  }
});
