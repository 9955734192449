
































































































































import store from "@/store/store";
import Learnlink from "@learnlink/interfaces";
import { PropType, computed, defineComponent, reactive, ref } from "@vue/composition-api";
import { updateCourseFeedback } from "@/api/course-feedback-service";
import handleError from "@/helpers/errors";
import Moment from "@/services/moment";

export default defineComponent({
  components: {},
  name: "CourseFeedbackCard",
  props: {
    feedback: {
      type: Object as PropType<Learnlink.CourseFeedback.default>,
      required: true
    }
  },
  setup(props) {
    const course = computed(() => {
      return store.state.courses.find((c) => c.ID === props.feedback.courseID);
    });
    const daysStringified = computed(() => {
      return props.feedback.days.join(", ");
    });
    const editor = reactive({
      editing: false,
      text: props.feedback.handledComment || "",
      handled: props.feedback.handled !== 0,
    });
    const loading = ref(false);
    const isEqual = computed(() => {
      return editor.text !== props.feedback.handledComment || editor.handled !== (props.feedback.handled !== 0);
    });

    async function updateFeedback() {
      if (loading.value) return;

      loading.value = true;
      try {
        const timeNow = Moment().unix();
        updateCourseFeedback({
          ...props.feedback,
          handled: editor.handled ? timeNow : 0,
          handledComment: editor.text,
          handledByUID: store.state.user?.uid || ""
        });
        props.feedback.handled = editor.handled ? timeNow : 0;
        props.feedback.handledComment = editor.text;
        props.feedback.handledByUID = store.state.user?.uid || "";
      }
      catch (e) {
        handleError(e);
        await store.dispatch("displayAlert", {
          color: "error",
          message: "Noe gikk galt – tilbakemeldingen ble ikke oppdatert.",
        });
      }

      loading.value = false;
    }

    return {
      course,
      editor,
      isEqual,
      loading,
      updateFeedback,
      daysStringified
    };
  }
});

