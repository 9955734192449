











































































import { watchEffect, defineComponent, PropType, ref, reactive } from "@vue/composition-api";
import { updateFeedback } from "@/api/feedback-service";
import handleError from "@/helpers/errors";
import Learnlink from "@learnlink/interfaces";
import Moment from "@/services/moment";
import store from "@/store/store";

export default defineComponent({
  name: "HandleFeedbackDialog",
  props: {
    feedback: {
      type: Object as PropType<Learnlink.Feedback.View.Admin>,
      required: true,
    },
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    handleFeedback: {
      type: Function as PropType<(value: boolean) => void>,
      required: true,
    }
  },
  emits: ["input"],
  setup(props, { emit }) {
    const vm = reactive({
      comment: "",
      didNothing: false,
      hidden: false,
    });

    const _value = ref(props.value);

    watchEffect(() => {
      emit("input", _value.value);
    });

    async function send() {
      try {
        const feedbackToUpdate = {
          ID: props.feedback.ID,
          handledByUID: store.state.user.uid,
          handledComment: vm.comment.trim(),
          handled: Moment().unix(),
          hidden: vm.hidden,
        } as Learnlink.Feedback.Update;

        _value.value = false;
        await updateFeedback(feedbackToUpdate);
        props.handleFeedback(true);
        await store.dispatch("displayAlert", {
          color: "green",
          message: "Suksess!",
        });
      }
      catch (e) {
        handleError(e);
        await store.dispatch("displayAlert", {
          color: "red",
          message: "Noe gikk galt – tilbakemeldingen ble ikke håndtert."
        });
      }
    }

    return {
      _value,
      send,
      vm,
    };
  },
});
