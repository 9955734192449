















































import { getAllNewsMessages } from "@/api/news-service";
import Learnlink from "@learnlink/interfaces";
import Moment from "@/services/moment";
import NewsCard from "./NewsCard.vue";
import Vue from "vue";

export default Vue.extend({
  components: { NewsCard },
  name: "AllNewsMessages",
  data() {
    return {
      loading: false,
      newsMessages: [] as Learnlink.NewsMessage.default[],
    };
  },
  async mounted() {
    await this.mount();
  },
  methods: {
    async mount() {
      this.loading = true;
      this.newsMessages = await getAllNewsMessages();
      this.loading = false;
    },
    readableDate(timestamp: number) {
      return Moment.unix(timestamp).format("LL");
    }
  }
});
