import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('h5',{staticStyle:{"width":"100%"}},[_vm._v(" Filter ")]),_c(VRow,[_c(VCol,{attrs:{"cols":"5"}},[_c('course-picker-vue',{attrs:{"multiple":true},model:{value:(_vm.vm.courses),callback:function ($$v) {_vm.$set(_vm.vm, "courses", $$v)},expression:"vm.courses"}})],1),_c(VCol,{attrs:{"cols":"5"}},[_c(VAutocomplete,{attrs:{"outlined":"","dense":"","multiple":true,"items":['Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag', 'Søndag'],"label":"Dager"},model:{value:(_vm.vm.days),callback:function ($$v) {_vm.$set(_vm.vm, "days", $$v)},expression:"vm.days"}})],1),_c(VCol,{attrs:{"col":"2"}},[_c(VAutocomplete,{attrs:{"items":['all', 'handled', 'unhandled'],"outlined":"","label":"Håndtert"},model:{value:(_vm.vm.handledFilter),callback:function ($$v) {_vm.$set(_vm.vm, "handledFilter", $$v)},expression:"vm.handledFilter"}})],1)],1),_c(VRow,[_c('h5',{staticStyle:{"width":"100%","text-align":"center"}},[_vm._v(" Søk etter courseID, email, navn, phone, feedback-id ")]),_c(VCol,{attrs:{"cols":"10"}},[_c(VTextField,{attrs:{"label":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VCol,{attrs:{"cols":"2"}},[_c(VBtn,{staticStyle:{"width":"100%"},attrs:{"color":"primary","large":"","dark":"","loading":_vm.isLoading,"data-test-id":"search-button"},on:{"click":function($event){return _vm.searchFunction()}}},[_vm._v(" Søk ")])],1)],1),(_vm.isLoading)?_c('div',{staticClass:"text-center"},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),_c(VDataIterator,{attrs:{"items":_vm.feedback,"items-per-page":100},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return _vm._l((items),function(item){return _c('course-feedback-card',{key:item.ID,staticClass:"mb-6",attrs:{"feedback":item}})})}}])}),(!_vm.isLoading)?_c('div',{staticStyle:{"width":"100%","display":"flex","justify-content":"center"}},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.previousPage()}}},[_vm._v(" Forrige side ")]),_c(VBtn,{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.resetPage()}}},[_vm._v(" Reset ")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.nextPage()}}},[_vm._v(" Last in flere! ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }