








































































































































import { computed, defineComponent, inject, onMounted, PropType, reactive } from "@vue/composition-api";
import { copyToClipboard } from "@/helpers/copyToClipboard";
import { getUser } from "@/api/user-service";
import { NegativeReasons } from "@/helpers/feedbackUtils";
import { ratingSymbols } from "@/helpers/stringUtils";
import { updateFeedback } from "@/api/feedback-service";
import handleError from "@/helpers/errors";
import HandleFeedbackDialog from "@/components/feedback/HandleFeedbackDialog.vue";
import Learnlink from "@learnlink/interfaces";
import Moment from "@/services/moment";
import SellerCard from "@/components/sellers/SellerCard.vue";
import store from "@/store/store";

export default defineComponent({
  name: "FeedbackCard",
  components: {
    HandleFeedbackDialog,
    SellerCard,
  },
  props: {
    feedback: {
      type: Object as PropType<Learnlink.Feedback.View.Admin>,
      required: true,
    },
  },
  setup(props) {
    const vm = reactive({
      profileDialogVisible: false,
      handleFeedbackDialogVisible: false,
      doNothingLoader: false,
      hidden: false,
    });

    const openEditCustomerDialog = inject<(customerUID: string) => void>("openEditCustomerDialog");
    const handleFeedback = inject<(uid: string) => Promise<void>>("handleFeedback");

    const userType = props.feedback.userRole === "parent" ? "Kunde" : props.feedback.userRole === "student" ? "Elev" : "Pro";

    const readableTime = computed(() => Moment.unix(props.feedback.created).format("LLL"));

    async function openUserDialog() {
      if (props.feedback.userRole === "seller") {
        vm.profileDialogVisible = true;
      }
      else {
        const uid = props.feedback.userRole === "parent"
          ? props.feedback.uid
          : (await getUser(props.feedback.uid)).parentUID;
        openEditCustomerDialog && openEditCustomerDialog(uid);
      }
    }

    async function updateHidden() {
      try {
        await updateFeedback({
          ID: props.feedback.ID,
          hidden: vm.hidden,
        });
        await store.dispatch("displayAlert", {
          color: "success",
          message: "Oppdatert! 🥳",
        });
      }
      catch (e) {
        handleError(e);
        await store.dispatch("displayAlert", {
          color: "error",
          message: "Noe gikk galt – tilbakemeldingen ble ikke oppdatert.",
        });
      }
    }

    async function doNothing() {
      vm.doNothingLoader = true;
      await updateFeedback({
        ID: props.feedback.ID,
        handledByUID: store.state.user.uid,
        handledComment: "",
        handled: Moment().unix(),
        hidden: false,
      });
      handle();
      await store.dispatch("displayAlert", {
        color: "green",
        message: "Suksess!",
      });
      vm.doNothingLoader = false;
    }

    onMounted(() => {
      vm.hidden = props.feedback.hidden;
    });
    const handle = () => {
      handleFeedback && handleFeedback(
        props.feedback.ID
      );

      props.feedback.handled = Moment().unix();
      props.feedback.handledByUID = store.state.user.uid;
      props.feedback.handledComment = "";
      props.feedback.hidden = false;
    };

    return {
      copyToClipboard,
      doNothing,
      NegativeReasons,
      openUserDialog,
      ratingSymbols,
      readableTime,
      updateHidden,
      userType,
      handle,
      vm,
    };
  },
});
