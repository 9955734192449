

















































































































import { defineComponent, provide, reactive, ref } from "@vue/composition-api";
import { getFeedbackWithQuery } from "@/api/feedback-service";
import { ratingSymbols } from "@/helpers/stringUtils";
import FeedbackCard from "@/components/feedback/FeedbackCard.vue";
import useAsyncData from "@/helpers/useAsyncData";
import Moment from "@/services/moment";

export default defineComponent({
  components: { FeedbackCard },
  name: "CustomerFeedback",
  setup() {
    const vm = reactive({
      filters: {
        rating: [] as number[],
        handled: "Alle" as string,
      },
      ratings: [1, 2, 3, 4, 5],
    });
    const search = ref("");
    const offsetPage = ref(0);
    const handlingOptions = ["Alle", "Håndtert", "Ikke håndtert"] as const;

    const toHandlingOption = (option: string) => {
      switch (option) {
        case "Alle":
          return undefined;
        case "Håndtert":
          return true;
        case "Ikke håndtert":
          return false;
        default:
          return undefined;
      }
    };

    const { isLoading, refresh, data: feedback } = useAsyncData(() => {
      return getFeedbackWithQuery("parent", {
        rating: vm.filters.rating,
        handled: toHandlingOption(vm.filters.handled),
        search: search.value === "" ? undefined : search.value,
        page: offsetPage.value,
      }).then((a) => {
        return { feedback: a.feedback.sort((a, b) => b.created - a.created) };
      });
    });

    const searchFunction = () => refresh();
    const handleFeedback = (uid: string) => {
      const value = feedback.value?.feedback.find((f) => f.uid !== uid);
      if (value && feedback.value) {
        value.handled = Moment().unix();
        feedback.value = { feedback: [...feedback.value.feedback] };
      }
    };

    const nextPage = () => {
      offsetPage.value += 1;
      refresh();
    };

    const previousPage = () => {
      offsetPage.value -= 1;

      if (offsetPage.value < 0) {
        offsetPage.value = 0;
      }
      else {
        refresh();
      }
    };

    const resetPage = () => {
      offsetPage.value = 0;
      refresh();
    };

    provide("handleFeedback", handleFeedback);
    return {
      isLoading,
      feedback,
      ratingSymbols,
      vm,
      nextPage,
      previousPage,
      search,
      resetPage,
      searchFunction,
      handlingOptions,
      offsetPage
    };
  }
});
