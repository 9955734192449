import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm._value,"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"width":"600"},on:{"click:outside":function($event){_vm._value = false},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm._value = false}}},[_c(VCard,{staticClass:"pa-6"},[_c(VContainer,[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[_c('b',[_vm._v(_vm._s(_vm.feedback.message))]),_c('p',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.feedback.feedbackFromName)+" ")]),(!_vm.feedback.seller)?_c(VCheckbox,{attrs:{"label":"Skjul feedback for Pro"},model:{value:(_vm.vm.hidden),callback:function ($$v) {_vm.$set(_vm.vm, "hidden", $$v)},expression:"vm.hidden"}}):_vm._e(),_c(VDivider)],1),_c(VCol,{attrs:{"cols":"8"}},[_c(VTextarea,{attrs:{"disabled":_vm.vm.didNothing,"label":"Hvordan håndterte du dette?","placeholder":"Jeg håndterte denne tilbakemeldingen ved å...","outlined":"","rows":"4","autofocus":"","hide-details":""},model:{value:(_vm.vm.comment),callback:function ($$v) {_vm.$set(_vm.vm, "comment", $$v)},expression:"vm.comment"}})],1),_c(VCol,{attrs:{"cols":"4"}},[(!_vm.feedback.seller)?_c(VCheckbox,{staticClass:"mr-10",attrs:{"label":"Jeg gjorde ingenting"},model:{value:(_vm.vm.didNothing),callback:function ($$v) {_vm.$set(_vm.vm, "didNothing", $$v)},expression:"vm.didNothing"}}):_vm._e()],1),_c(VCol,{staticClass:"text-center",attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"mr-2 mt-3",attrs:{"text":"","color":"error"},on:{"click":function($event){_vm._value = false}}},[_vm._v(" Avbryt ")]),_c(VBtn,{staticClass:"mt-3",attrs:{"color":"success","disabled":!_vm.vm.didNothing && _vm.vm.comment.length < 5},on:{"click":function($event){return _vm.send()}}},[_vm._v(" Fullfør ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }