import { axios } from "@/configuration";
import Learnlink from "@learnlink/interfaces";

const ENDPOINT = "/admin/news";

export async function createNewsMessage(newsMessage: Learnlink.NewsMessage.Create): Promise<Learnlink.NewsMessage.default> {
  const res = await axios.post<Learnlink.NewsMessage.default>(
    `${ENDPOINT}`,
    newsMessage
  );
  return res.data;
}

export async function getAllNewsMessages(): Promise<Learnlink.NewsMessage.default[]> {
  const res = await axios.get<Learnlink.NewsMessage.default[]>(
    `${ENDPOINT}/all`
  );
  return res.data;
}

export async function updateNewsMessage(newsMessage: Learnlink.NewsMessage.Update): Promise<Learnlink.NewsMessage.default> {
  const res = await axios.put<Learnlink.NewsMessage.default>(
    `${ENDPOINT}/${newsMessage.ID}`,
    newsMessage
  );
  return res.data;
}

export async function deleteNewsMessage(ID: string): Promise<Learnlink.NewsMessage.default> {
  const res = await axios.delete<Learnlink.NewsMessage.default>(
    `${ENDPOINT}/${ID}`
  );
  return res.data;
}
