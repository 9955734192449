import { axios } from "@/configuration";
import Learnlink from "@learnlink/interfaces";

const ENDPOINT = "/admin/feedback";

export async function getAllFeedback(userType: Learnlink.Type.Role): Promise<Learnlink.Feedback.View.Admin[]> {
  const res = await axios.get<Learnlink.Feedback.View.Admin[]>(
    `${ENDPOINT}/all/${userType}`
  );
  return res.data;
}

export async function getFeedbackWithQuery(userRole: Learnlink.Type.Role, options?: {
  handled?: boolean;
  search?: string;
  page?: number;
  rating?: number[];
  negativeReason?: string[];
  courses?: string[];
}): Promise<{feedback: Learnlink.Feedback.View.Admin[]}> {
  const url = `${ENDPOINT}/all/${userRole}`;

  const params = new URLSearchParams();

  if (options?.search) {
    params.append("search", options.search);
  }
  if (options?.page) {
    params.append("page", options.page.toString());
  }
  if (typeof options?.handled === "boolean") {
    params.append("handled", options.handled.toString());
  }
  if (options?.rating) {
    params.append("rating", JSON.stringify(options.rating));
  }
  if (options?.negativeReason) {
    params.append("negativeReason", JSON.stringify(options.negativeReason));
  }
  if (options?.courses) {
    params.append("courses", JSON.stringify(options.courses));
  }

  const res = await axios.get<{feedback: Learnlink.Feedback.View.Admin[]}>(
    url + "?" + params.toString()
  );

  return res.data;
}

export async function updateFeedback(feedback: Learnlink.Feedback.Update): Promise<Learnlink.Feedback.Feedback> {
  const res = await axios.put<Learnlink.Feedback.Feedback>(
    `${ENDPOINT}/${feedback.ID}`,
    feedback
  );
  return res.data;
}
